var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("c-plant", {
          attrs: { required: "", type: "edit", name: "plantCd" },
          on: { input: _vm.getDetail },
          model: {
            value: _vm.data.plantCd,
            callback: function ($$v) {
              _vm.$set(_vm.data, "plantCd", $$v)
            },
            expression: "data.plantCd",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "col-12 card" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v(" " + _vm._s(_vm.$language("비상연락망")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "card-body no-padding-card" },
        [
          _c("q-table", {
            attrs: {
              grid: "",
              title: "",
              data: _vm.data.networks,
              "hide-header": "",
              "hide-bottom": "",
              "rows-per-page-options": [0],
              "virtual-scroll": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (props) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                      },
                      [
                        _c(
                          "q-card",
                          {
                            staticClass: "mobileTableCardLayer",
                            on: {
                              click: function ($event) {
                                return _vm.linkClick(null, props.row)
                              },
                            },
                          },
                          [
                            _c(
                              "q-card-section",
                              { staticClass: "grid-card-etc" },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "text-grid-etc",
                                      _vm.getColor1(props.row.emeConcactTypeCd),
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(props.row.emeConcactTypeNm) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("div", { class: ["text-grid-etc"] }, [
                                  _vm._v(
                                    " " + _vm._s(props.row.contactNm) + " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("q-card-section", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-grid-title q-mt-sm q-mb-xs",
                                },
                                [
                                  _c("q-btn", {
                                    attrs: {
                                      color: "red",
                                      icon: "call",
                                      label: props.row.contactNo,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.callContact(
                                          props.row.contactNo
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.data.networks.length === 0
            ? _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                },
                [
                  _c(
                    "q-card",
                    { staticClass: "mobileTableCardLayer-main" },
                    [
                      _c(
                        "q-card-section",
                        {
                          staticClass: "text-center",
                          attrs: { horizontal: "" },
                        },
                        [
                          _c("q-card-section", { staticClass: "q-pt-xs" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-grid-title text-grid-titlemain q-mt-sm q-mb-xs",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$language(
                                      "등록된 비상연락망이 없습니다."
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "초기 대응법" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c("div", {
                staticClass: "emer-content",
                domProps: { innerHTML: _vm._s(_vm.data.remark) },
              }),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12", staticStyle: { "margin-top": "20px" } },
      [
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfo,
            editable: false,
            label: "첨부파일",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }