<template>
  <div class="row">
    <div class="col-12">
      <c-plant required  type="edit" name="plantCd" v-model="data.plantCd" @input="getDetail"/>
    </div>
    <div class="col-12 card">
      <div class="card-header">
        {{$language('비상연락망')}}
      </div>
      <div class="card-body no-padding-card">
        <q-table
          grid
          title=""
          :data="data.networks"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColor1(props.row.emeConcactTypeCd)]">
                    {{ props.row.emeConcactTypeNm }}
                  </div>
                  <div :class="['text-grid-etc']">
                    {{ props.row.contactNm }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">
                    <q-btn color="red" icon="call" :label="props.row.contactNo" @click="callContact(props.row.contactNo)"/>
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="data.networks.length === 0">
          <q-card class="mobileTableCardLayer-main">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title text-grid-titlemain q-mt-sm q-mb-xs">{{$language('등록된 비상연락망이 없습니다.')}}</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <div class="col-12">
      <c-card title="초기 대응법" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="emer-content" v-html="data.remark"></div>
        </template>
      </c-card>
    </div>
    <div class="col-12" style="margin-top:20px;">
      <c-upload 
        :attachInfo="attachInfo"
        :editable="false"
        label="첨부파일">
      </c-upload>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'eme-contact-pop',
  data() {
    return {
      deleteUrl: '',
      editable: true,
      data: {
        plantCd: null,
        emeConcactId: '', 
        remark: '',
        networks: []
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EME_CONTACT',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.detailUrl = selectConfig.sop.eme.contact.get.url;
      this.getDetail();
    },
    getDetail() {
      if (this.data.plantCd) {
        this.$http.url = this.detailUrl;
        this.$http.param = {plantCd: this.data.plantCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data) {
            this.data = _result.data;
            this.$set(this.attachInfo, 'taskKey', this.data.emeConcactId);
          }
        },);
      }
    },
    getColor1(cd) {
      var cls = '';
      switch(cd) {
        case 'ECTC000001': // 비상연락망
          cls = 'txt-box-grid text-primary-box';
          break;
        case 'ECTC000002': // 근처 의료기관 연락처
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
    callContact(phoneNo) {
      location.href = 'tel:' + phoneNo;
    }
  }
};
</script>

<style lang="scss">
.emer-content {
  padding: 10px 20px !important;
}
.emer-title {
  font-weight: 600;
  font-size: 1.1em;
  padding: 10px 20px;
}
</style>